<template>
   <section class="desksweek h-100">
      <div class="h-100">
         <div class="grid-margin">
            <div class="h-100 container-fluid px-5 d-flex align-items-center">
                
                <div class='row d-flex justify-content-center'  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page' style="height: 85%;">
                  
                    <!--d-none d-md-flex-->
                    <div class="h-100 col-12 col-md-4 col-lg-4 col-xl-3 d-flex flex-column justify-content-between my-3 my-md-0" style="background-color: white; padding: 24px 20px; border-radius: 12px;">
                         <!--&& state.quest_manager.chain_quest-->
                         
                         
                         
                         <div class="my-2" v-if="state.player_current_profession">
                            <p class="mb-3" style="font-size: 20px; color: #2E3B4B; font-weight: 600;">Profession</p>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <img :src="state.player_current_profession.prof_ico" style="width: 40px; height: 40px;">
                                    <p class="ml-2" style="font-weight: 600; font-size: 14px; color: #2E3B4B;" v-html="state.player_current_profession.name"></p>
                                </div>
                                <div style="padding: 4px 8px; background: #F4F5FD; border-radius: 40px;">
                                    <!--<p style="font-weight: 500; font-size: 14px; color: #2E3B4B;">{{state.player_current_profession.lvl_info.current_lvl}} lvl</p>-->
                                    
                                        <div class="custom_tooltip" data-side="bottom">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p style="font-weight: 500; font-size: 14px; color: #2E3B4B;">{{state.player_current_profession.lvl_info.current_lvl}} lvl</p>
                                            </div>
                                            <span class="tooltiptext">{{state.player_current_profession.name}}: {{state.player_current_profession.lvl_info.current_lvl}} lvl</span>
                                        </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="progress" style="background-color: #DCE0EA; height: 10px;">
                                  <div class="progress-bar" role="progressbar" :style="{'width': state.player_current_profession.lvl_info.lvl_progress_percentage, 'background': 'linear-gradient(90deg, #5C12CF 2.92%, #A76EFF 100%)'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                
                                <div v-if="Number(state.player_current_profession.lvl_info.current_exp) < Number(state.player_current_profession.lvl_info.current_lvl_max)" 
                                     class="mt-2 d-flex  align-items-center justify-content-center">
                                    <div class="d-flex align-items-center">
                                        <div class="custom_tooltip" data-side="bottom">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">{{state.player_current_profession.lvl_info.current_exp}}</p>
                                                <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image" style="width: 20px;"/>
                                            </div>
                                            <span class="tooltiptext">Your Professional exp</span>
                                        </div>
                                    </div>
                                    <p class="mx-2">/</p>
                                    <div class="d-flex align-items-center">
                                        <div class="custom_tooltip" data-side="bottom">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">{{state.player_current_profession.lvl_info.current_lvl_max}}</p>
                                                <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image" style="width: 20px;" />
                                            </div>
                                            <span class="tooltiptext">Professional exp to lvl up</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-else class="mt-2 d-flex  align-items-center justify-content-center">
                                    <p style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">Max Level</p>
                                </div>
                                
                            </div>
                        </div>
                         
                         
                         <div v-if="state.quest_manager&& state.quest_manager.chain_quest.length > 0">
                            <div v-for="chain_quest in state.quest_manager.chain_quest" :key="chain_quest.id" class="chain__outer">
                                <p class="mb-3" style="font-size: 20px; color: #2E3B4B; font-weight: 600;">Energy circuit</p>
                                
                                <div class="chain row">
 <!--d-flex justify-content-between align-items-center-->
                                    <div class="col-9 col-md-8 col-lg-9 d-flex justify-content-start align-items-center">
                                        <div class="row justify-content-start">
                                        <template v-for="item in chain_quest.what_to_get">
                                            <template v-for='j in (Number(item.amount))'>
                                                <div class="custom_tooltip col-1" data-side="bottom" :key="item.item_id + j" style="z-index: auto;">
                                                    <div>
                                                        <img :src="item.item_img">
                                                    </div>
                                                    <span class="tooltiptext" style="z-index: 2;">{{item.item_name}}</span>
                                                </div>
                                            </template>
                                        </template>
                                        </div>
                                    </div>
                                    
                                    <div class="col-3 col-md-4 col-lg-3 d-flex justify-content-end align-items-center">
                                        
                                            <div class="custom_tooltip" data-side="bottom">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <p class="chain_prof_exp mr-1">+{{chain_quest.prof_exp_reward}}</p>
                                                    <img src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                                                </div>
                                                <span class="tooltiptext">Professional exp</span>
                                            </div>
                                        
                                    </div>
                                </div>
                                
                                <div class="mt-3" v-for="item in chain_quest.what_to_give" :key="item.item_id">
                                    <div class="progress" style="background-color: #DCE0EA; height: 10px;">
                                      <div class="progress-bar" role="progressbar" 
                                           :style="{'width': ((Number(chain_quest.quest_progress[item.item_id]) * 100) / Number(item.amount) ) + '%'}" 
                                           aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                      <!--chain_quest.persent_done-->
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center chain_progress mt-2">
                                        
                                        <div class="custom_tooltip" data-side="bottom">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p>{{chain_quest.quest_progress[item.item_id]}}</p>
                                                <img class="ml-1" :src="item.item_img" alt="image"/>
                                            </div>
                                            <span class="tooltiptext">Earned</span>
                                        </div>
                                        
                                        <p class="mx-2">/</p>
                                        
                                        <div class="custom_tooltip" data-side="bottom">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <p>{{item.amount}}</p>
                                                <img class="ml-1" :src="item.item_img" alt="image"/>
                                            </div>
                                            <span class="tooltiptext">Required</span>
                                        </div>

                                    </div>
                                </div>
                                
                                <div>
                                    <button class="mt-3" @click="open_chain_modal">Change</button>
                                </div>
                                
                            </div>
                        </div>
                        

                        <div v-if="state.quest_manager">
                            <p style="font-size: 20px; color: #2E3B4B; font-weight: 600;">Contracts</p>
                            
                            <div class="d-flex flex-column">
                                
                                <div v-if="state.quest_manager.side_quests.length > 0">
            <div class="side_quest__outer" v-for="quest_data in state.quest_manager.side_quests" :key="quest_data.id">
                <div class="">
                       
                        <div v-for="item in quest_data.what_to_give" :key="item.item_id" class="d-flex align-items-center justify-content-between my-3">
                            <div>
                                <p style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">Collect:</p>
                            </div>
                            <div class="d-flex  align-items-center">
                                <p style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">{{item.current_amount}}</p>
                                <p class="mx-2" style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">/</p>
                                <p style="font-style: normal; font-weight: 500; font-size: 15px; color: #000000;">{{item.amount}}</p>
                                <img class="ml-2" :src="item.item_img" alt="..." style="height: 20px; width: 20px;">
                            </div>
                        </div>
                        
                </div>
                
                <button v-if="quest_data.ready_to_complete == 1" class="side_quest__button active" @click="show_side_quest__modal(quest_data)">Complete contract</button>
                <button v-else class="side_quest__button" @click="show_side_quest__modal(quest_data)">Show details</button>
            </div>
                                </div>

                                <div v-else class="mt-3" v-for="i in 1" :key="i">
                                    <div class="side_quests__container">
                                        <p style="opacity: 0.6;">Collect:</p>
                                        <i class="mdi mdi-lock"></i>
                                    </div>
                                    <button class="side_quest__button mt-3" @click="$root._PAGE().move_to($store, cdata, '/book/boutchoose/')">Go to Story</button>
                                    
                                </div>
                                
                            </div>
                                

                        </div>
                        
                    </div>
                    
                    <div class="row h-100 offset-xl-1 col-12 col-md-8 col-lg-8 col-xl-8">
                        <div v-for="(item, index) in state.desks" :key="index"  class="col-12 col-xl-6 d-flex justify-content-center justify-content-lg-end desks_outer" 
                             style="
                                width: 100%;
                                min-height: 156px;
                             ">
<!--                                min-width: 350px;
                                min-height: 156px;
                                width: 100%;
                                height: 100%;-->

<!--                                width: 350px; 
                               height: 156px; -->
                            <div class="noselect" style="
                                width: 100%;
                                min-height: 156px;
                               
                               background-color: white; 
                               display: flex;
                               flex-direction: column;
                               justify-content: space-between;
                               padding: 16px;
                               box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.05);
                               border-radius: 16px;
                               cursor: pointer;
                               position: relative;"

                               :style="[state.desks_limit[index]['block_by_limit'] ? {'opacity': '0.5'} : {'opacity': '1'}]">

                               <div v-if="!state.desks_limit[index]['block_by_limit']" class="lvl_cover_element" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2;" v-bind:current_deskweek='index' @click="move_to_card('/g1/cardweek/')"></div>

                               <div class="lvl_header mb-2 d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                          <p class="mr-2 desk_name">R-{{ pos0(index)  }}</p>
                                          <img v-bind:src="cdata.system.cnf['server_cloud']+form.json_links[pos0(index)].desk_img"  />
                                    </div>
                                  
                                  <div class="d-flex align-items-center">
                                      
                                        <div class="desk_reward__outer" v-if="state.desks_joker[index].joker_per_task > 0">
                                            <div class="custom_tooltip" data-side="bottom">
                                                <div class="desk_reward_inner">
                                                    <p class="mr-1 desk_reward">{{ state.desks_joker[index].joker_per_task }}</p>
                                                    <img src="/resource/img/game_ico/joker_coin.svg" alt="image"/>
                                                </div>
                                                <span class="tooltiptext">Universal Charge</span>
                                            </div>
                                        </div>
                                      
                                        <div class="desk_reward__outer">
                                            <div class="custom_tooltip" data-side="bottom">
                                                <div class="desk_reward_inner">
                                                    <p class="mr-1 desk_reward">{{ state.desks_gold[index].gold_per_task }}</p>
                                                    <img src="/resource/img/game_ico/gold.svg" alt="image"/>
                                                </div>
                                                <span class="tooltiptext">Credits</span>
                                            </div>
                                        </div>
                                      
                                        <div class="desk_reward__outer">
                                            <div class="custom_tooltip" data-side="bottom">
                                                <div class="desk_reward_inner">
                                                    <p v-if="Number(item.solved_to_learn_full_layout) < Number(item.needed_to_learn_full_layout)" class="mr-1 desk_reward">{{(Number(state.desks_exp[index].exp_per_task) * Number(state.desks_exp[index].multiplier_value))}}</p>
                                                    <p v-else class="mr-1 desk_reward">{{ state.desks_exp[index].exp_per_task }}</p>
                                                    <img src="/resource/img/game_ico/exp.svg" alt="image"/>
                                                </div>
                                                <span class="tooltiptext">Experience</span>
                                            </div>
                                        </div>
                                  </div>
                               </div>
                               <div class="lvl_body" style="
                                  display: flex;
                                  flex-direction: column;
                                  height: inherit;
                                  justify-content: space-between;">
                                  <div class="lvl_body__energy" style="display:flex; align-items: end; position: relative;">
                                     <img v-if="!state.desks_limit[index]['block_by_limit']" src="/resource/img/game_ico/energy_crystal.svg" alt="image" style="width: 32px; z-index: 10;"/>
                                     <img v-else-if="state.desks_limit[index]['block_by_limit']" src="/resource/img/game_ico/lock.svg" alt="image" style="z-index: 10;"/>
                                     
                                    <div class="progress" style="height: 12px; background: #F4F5FD; border-radius: 20px; width: 95%; position: absolute; right: 0;">
                                        <div class="progress-bar" role="progressbar" :style="{ 'width': state.desks_limit[index]['percent_limit'] }" :aria-valuenow="state.desks_limit[index]['percent_limit']" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>

<!--                                     <p v-if="!state.desks_limit[index]['block_by_limit']" style="font-weight: 600; font-size: 14px; color: #49BDFF;">Energy on <span style="font-weight: 500;">({{state.desks_limit[index]['percent_limit']}})</span></p>
                                     <p v-else-if="state.desks_limit[index]['block_by_limit']" style="font-weight: 500; font-size: 14px; color: #ff0c71c7;">Energy off</p>-->
                                  </div>
<!--                                  <div class="lvl_body__star" style="display:flex; align-items: center; justify-content: space-between;">
                                     <p style="font-weight: 500; font-size: 14px; color: #FFA827;">Keep solving and unlock the star!</p>
                                     <img src="/resource/img/game_ico/star.svg" alt="image"/>
                                  </div>-->
<!--                                  <div class="lvl_body__info" style="display:flex; align-items: center;">
                                     <img src="/resource/img/game_ico/lock.svg" alt="image" class="mr-1"/>
                                     <p v-if="!state.desks_limit[index]['block_by_limit']" style="font-weight: 400; font-size: 14px;">Get x2 points for <b>3 cards</b>!</p>
                                     <p v-else-if="state.desks_limit[index]['block_by_limit']" style="font-weight: 400; font-size: 14px;">This deck is not available until tomorrow</p>
                                  </div>-->
                               </div>
                               
                               <!--ADMIN DATA-->
                               <p style="font-size: 10px; font-weight: bold;" v-if="cdata.solved_info__admin_controls">Correct Daily task count: {{state.desks_limit[index].solved_count}}</p>
                               <p style="font-size: 10px; font-weight: bold;" v-if="cdata.solved_info__admin_controls">Correct All task count: {{item.solved_tasks}}</p>
                               <p style="font-size: 10px; font-weight: bold;" v-if="cdata.solved_info__admin_controls">Correct All card count: {{item.solved_cards}}</p>
                               <!--END ADMIN DATA-->
                               
                                  <div class="lvl_body__theme_progress d-flex justify-content-between align-items-center">
                                      <div class="d-flex align-items-center">
                                          
                                            <div class="custom_tooltip" data-side="bottom">
                                                <div class="learn_to_full_layout__outer">
                                                    <img src="/resource/img/game_ico/trophy.svg" alt="image"/>
                                                    <p class="learn_to_full_layout">{{item.solved_to_learn_full_layout}} out of {{item.needed_to_learn_full_layout}}</p>
                                                    <i v-if="Number(item.solved_to_learn_full_layout) == Number(item.needed_to_learn_full_layout)" class="mdi mdi-check-circle"></i>
                                                </div>
                                                <span class="tooltiptext">Reaction types mastered</span>
                                            </div>
<!--                                        <img src="/resource/img/game_ico/trophy.svg" alt="image" class="mr-1" style="width: 32px;"/>
                                        <p class="m-0 p-0 learn_to_full_layout">{{item.solved_to_learn_full_layout}} out of {{item.needed_to_learn_full_layout}}</p>
                                        <i v-if="Number(item.solved_to_learn_full_layout) == Number(item.needed_to_learn_full_layout)" class="mdi mdi-check-circle ml-1" style="font-size:24px; color: #55D18E;"></i>-->
                                      </div>
                                      <div v-if="item.solved_cards < 3"
                                           class="bonus_income">
                                          <p><img src="/resource/img/game_ico/gold.svg" alt="image"/> x2 income</p>
                                      </div>
                                  </div>
                               
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    
                    
                    
<!-- CHAIN Modal -->
<transition name="pop" appear>
<div v-if="cdata.business_logic.show_chain_modal" class="custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
   <div class="modal_custom_dialog" role="document">
       <!--modal-content-->
      <div class="h-100">
         <div class="custom_modal__body h-100 container-fluid p-5" style="overflow-y:auto;">
             
            <div>
               <div @click="cdata.business_logic.show_chain_modal = false" class="d-flex align-items-center" style="cursor: pointer; padding: 10px; background: #FFFFFF; box-shadow: 0px 2px 0px #DCE0EA; border-radius: 12px; width: 44px; height: 44px;">
                  <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
               </div>
                
                <!--PROFESSIONS-->
               <div class="d-flex justify-content-center">
                  <b-carousel
                     id="chain_modal__prof_slider"
                     v-model="cdata.business_logic.prof_slider_index"
                     :interval="0"
                     controls
                     img-width="1024"
                     img-height="480"
                     style="text-shadow: 1px 1px 2px #333;"
                     >
                     <b-carousel-slide v-for="profession in state.player_professions" :key="profession.prof_id">
                        <template #img>
                           <div class="prof_card__outer d-flex justify-content-center">
                              <div :class="((profession.is_active) ? 'p-3 d-flex flex-column justify-content-between prof_card active' : 'p-3 d-flex flex-column justify-content-between prof_card')">
                                 <div class="d-flex align-items-center justify-content-between prof_card__header">
                                    <div class="d-flex align-items-center">
                                       <img :src="profession.prof_ico">
                                       <p class="ml-2 prof_card__header__prof_name" v-html="profession.name"></p>
                                    </div>
                                    <div class="prof_card__header__prof_lvl_outer">
                                       <p class="prof_card__header__prof_lvl">{{profession.lvl_info.current_lvl}} lvl</p>
                                    </div>
                                 </div>
                                 <div>
                                    <img :src="profession.prof_picture" class="w-100 slider_prof_picture">
                                 </div>
                                 <div class="d-flex justify-content-around">
                                    <div v-for="prof_resource in profession.prof_resources" :key="prof_resource.item_id" class="prof_card_resources">
                                             <img :src="prof_resource.item_img" class="w-100">
                                    </div>
                                 </div>
                                 <div v-if="profession.is_active" class="active_prof_signboard__outer">
                                    <div class="active_prof_signboard">
                                       <p>Active</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </template>
                     </b-carousel-slide>
                  </b-carousel>
               </div>
                <!--PROFESSIONS END-->
                
                <!--EXTENSIONS-->
               <div class="chain__extension_row row">
                    <!--FORMULAS-->
                  <div class="col-12 col-xl-4 d-flex flex-column align-items-center">
                     <div class="chain__extension_row__name">
                         <img class="mr-2" src="/resource/img/game_ico/extensions_ico/Formulas_category.svg" alt="category_ico" style="width: 30px; height: 30px;">
                        <p>Formulas</p>
                     </div>
                     <div class="chain__extension_row__button__outer w-100" @click="show_available_extensions('Formulas')">
                         <div class="d-flex h-100 justify-content-between">
                            
                             
                             
                             
                             
                             
                             
                        <div v-if="cdata.business_logic.chain.selected_extensions.Formulas != null" class="chain__extension_row__button col-11 p-0">
                           <!--<div class="row">-->                         
                           <div class="col-3 chain__extension_row__button__tier">
                              <p class="text-nowrap">Tier {{cdata.business_logic.chain.selected_extensions.Formulas.tier}}</p>
                           </div>
                           <div class="col-9 chain_part">
                              <div class="row justify-content-between align-items-center h-100">
                                 <div class="col-2">
                                    <div class="d-flex align-items-center justify-content-start">
                                       <div class="d-flex">
                                          <div v-for="item in cdata.business_logic.chain.selected_extensions.Formulas.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                                             <div class="d-flex align-items-center justify-content-center">
                                                <p>{{item.amount}}</p>
                                                <img class="ml-1" :src="item.item_img" alt="...">
                                             </div>
                                          </div>
                                       </div>
                                       
                                    </div>
                                 </div>
                                  <i class="col-2 mdi mdi-arrow-right" style="font-size: 36px;"></i>
                                 <div class="col-4">
                                    <div class="row justify-content-center">
                                       <template v-for="item in cdata.business_logic.chain.selected_extensions.Formulas.what_to_get">
                                          <template v-for='j in (Number(item.amount))'>
                                             <img class="col-3 p-0" :src="item.item_img" :key='item.item_id + j'>
                                          </template>
                                       </template>
                                    </div>
                                 </div>
                                 <div class="col-3">
                                    <div class="d-flex align-items-center justify-content-end">
                                       <p class="chain_prof_exp">+{{cdata.business_logic.chain.selected_extensions.Formulas.prof_exp_reward}}</p>
                                       <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div v-else class="chain__extension_row__button empty col-11">
                           <p>Empty  slot</p>
                        </div>
                        <div class="chain__extension_row__button__chevron category_formula col-1">
                           <i class="mdi mdi-chevron-right"></i>
                        </div>
                        
                         
                         
                         
                         
                         
                         
                         
                         
                         </div>
                    </div>
                  </div>
                    <!--FORMULAS END-->
                    
                    <!--ITEMS-->
                  <div class="col-12 col-xl-4 d-flex flex-column align-items-center">
                     <div class="chain__extension_row__name">
                         <img class="mr-2" src="/resource/img/game_ico/extensions_ico/Items_category.svg" alt="category_ico" style="width: 30px; height: 30px;">
                        <p>Items</p>
                     </div>
                     <div class="chain__extension_row__button__outer w-100" @click="show_available_extensions('Items')">
                         <div class="d-flex h-100 justify-content-between">
                         
                            <div v-if="cdata.business_logic.chain.selected_extensions.Items != null" class="chain__extension_row__button col-11 p-0">
                               <!--<div class="row">-->                         
                               <div class="col-3 chain__extension_row__button__tier">
                                  <p class="text-nowrap">Tier {{cdata.business_logic.chain.selected_extensions.Items.tier}}</p>
                               </div>
                               <div class="col-9 chain_part">
                                  <div class="row justify-content-between align-items-center h-100">
                                     <div class="col-2">
                                        <div class="d-flex align-items-center justify-content-start">
                                           <div class="d-flex">
                                              <div v-for="item in cdata.business_logic.chain.selected_extensions.Items.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                                                 <div class="d-flex align-items-center justify-content-center">
                                                    <p>{{item.amount}}</p>
                                                    <img class="ml-1" :src="item.item_img" alt="...">
                                                 </div>
                                              </div>
                                           </div>
                                           
                                        </div>
                                     </div>
                                      <i class="mdi mdi-arrow-right col-2" style="font-size: 36px;"></i>
                                     <div class="col-4">
                                        <div class="row justify-content-center">
                                           <template v-for="item in cdata.business_logic.chain.selected_extensions.Items.what_to_get">
                                              <template v-for='j in (Number(item.amount))'>
                                                 <img class="col-3 p-0" :src="item.item_img" :key='item.item_id + j'>
                                              </template>
                                           </template>
                                        </div>
                                     </div>
                                     <div class="col-3">
                                        <div class="d-flex align-items-center justify-content-end">
                                           <p class="chain_prof_exp">+{{cdata.business_logic.chain.selected_extensions.Items.prof_exp_reward}}</p>
                                           <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>



                            <div v-else class="chain__extension_row__button empty col-11">
                               <p>Empty  slot</p>
                            </div>
                            <div class="chain__extension_row__button__chevron category_item col-1">
                               <i class="mdi mdi-chevron-right"></i>
                            </div>
                         
                        </div>
                         
                     </div>
                  </div>
                    <!--ITEMS END-->
                    
                    <!--BOOSTERS-->
                  <div class="col-12 col-xl-4 d-flex flex-column align-items-center">
                     <div class="chain__extension_row__name">
                         <img class="mr-2" src="/resource/img/game_ico/extensions_ico/Boosters_category.svg" alt="category_ico" style="width: 30px; height: 30px;">
                        <p>Boosters</p>
                     </div>
                     <div class="chain__extension_row__button__outer w-100" @click="show_available_extensions('Boosters')">
                         
                         <div class="d-flex h-100 justify-content-between">
                         
                            <div v-if="cdata.business_logic.chain.selected_extensions.Boosters != null" class="chain__extension_row__button col-11 p-0">
                               <!--<div class="row">-->                         
                               <div class="col-3 chain__extension_row__button__tier">
                                  <p class="text-nowrap">Tier {{cdata.business_logic.chain.selected_extensions.Boosters.tier}}</p>
                               </div>

                               <div class="col-7 chain_part d-flex align-items-center justify-content-center">
                                  <div class="row justify-content-between align-items-center">
                                     <div class="col-2">
                                        <div class="d-flex align-items-center justify-content-start">
                                           <div class="d-flex">
                                              <div v-for="item in cdata.business_logic.chain.selected_extensions.Boosters.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                                                 <div class="d-flex align-items-center justify-content-center">
                                                    <p>{{item.amount}}</p>
                                                    <img class="ml-1" :src="item.item_img" alt="...">
                                                 </div>
                                              </div>
                                           </div>
                                           
                                        </div>
                                     </div>
                                      <i class="mdi mdi-arrow-right col-2" style="font-size: 36px;"></i>
                                     <div class="col-4">
                                        <div class="row justify-content-center">
                                           <template v-for="item in cdata.business_logic.chain.selected_extensions.Boosters.what_to_get">
                                              <template v-for='j in (Number(item.amount))'>
                                                 <img class="col-6 p-0" :src="item.item_img" :key='item.item_id + j'>
                                              </template>
                                           </template>
                                        </div>
                                     </div>
                                     <div class="col-3">
                                        <div class="d-flex align-items-center justify-content-end">
                                           <p class="chain_prof_exp">+{{cdata.business_logic.chain.selected_extensions.Boosters.prof_exp_reward}}</p>
                                           <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                                        </div>
                                     </div>
                                  </div>
                               </div>

                                <div class="col-2 chain__extension_row__button__booster_limit">
                                    <img src="/resource/img/game_ico/extensions_ico/booster_ico.svg" alt="booster_ico">
                                    <p style="white-space: nowrap;">{{(cdata.business_logic.chain.selected_extensions.Boosters.item_usage_limit)?cdata.business_logic.chain.selected_extensions.Boosters.item_usage_limit:cdata.business_logic.chain.selected_extensions.Boosters.usage_limit}} / {{cdata.business_logic.chain.selected_extensions.Boosters.usage_limit}}</p>
                               </div>
                            </div>
                            <div v-else class="chain__extension_row__button empty col-11">
                               <p>Empty  slot</p>
                            </div>
                            <div class="chain__extension_row__button__chevron category_booster col-1">
                               <i class="mdi mdi-chevron-right"></i>
                            </div>
                         
                        </div>
                         
                     </div>
                  </div>
                    <!--BOOSTERS END-->
               </div>
                <!--EXTENSIONS END-->
                
            </div>

<div class="d-flex justify-content-center" :style="(state.form_chain_quest.prof_exp_bonus_for_category.length > 0) ? '' : 'margin-bottom: 36px; transition-delay: 1s; transition-property: margin-bottom;'">
    <transition name="slide" appear>
            
                <div v-if="state.form_chain_quest.prof_exp_bonus_for_category.length > 0" class="bonus_exp_for_slots d-flex">
                    <div class="mx-2 d-flex" v-for="item in state.form_chain_quest.prof_exp_bonus_for_category" :key="item.category_name">
                        <img :src="item.category_img" alt="category_image">
                        <p class="mx-2">+ {{item.bonus__prof_exp}}</p>
                        <img src="/resource/img/game_ico/prof_exp.svg" alt="prof_exp_ico">
                    </div>
                </div>
            
    </transition>
</div>
    
<!--    <transition name="slide" appear>
            <div v-if="state.form_chain_quest.prof_exp_bonus_for_category.length > 0" class="d-flex justify-content-center mt-3">
                <div class="bonus_exp_for_slots d-flex">
                    <div class="mx-2 d-flex" v-for="item in state.form_chain_quest.prof_exp_bonus_for_category" :key="item.category_name">
                        <img :src="item.category_img" alt="category_image">
                        <p class="mx-2">+ {{item.bonus__prof_exp}}</p>
                        <img src="/resource/img/game_ico/prof_exp.svg" alt="prof_exp_ico">
                    </div>
                </div>
            </div>
    </transition>-->
             
            <div class="final_chain_outer">
                <div class="">
                   <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">
                       
                        <div v-for="item in state.form_chain_quest.chain_quest_to_display.what_to_give" :key="item.item_id">
                           <div class="d-flex  align-items-center">
                              <p>{{item.amount}}</p>
                              <img class="ml-2" :src="item.item_img" alt="...">
                           </div>
                        </div>
                       
                        <i class="mdi mdi-arrow-right mx-3" style="font-size: 36px;"></i>
                        
                        <div class="d-flex justify-content-center">
                            <template v-for="item in state.form_chain_quest.chain_quest_to_display.what_to_get">
                               <template v-for='j in (Number(item.amount))'>

                                    <div class="custom_tooltip" data-side="bottom" :key='item.item_id + j'>
                                       <div class="d-flex align-items-center justify-content-center">
                                          <img :src="item.item_img" style="max-height: 30px;">
                                       </div>
                                       <span class="tooltiptext">{{item.item_name}}</span>
                                    </div>
                                   <!--<img :src="item.item_img" :key='item.item_id + j' style="max-height: 30px;">-->
                               </template>
                            </template>
                        </div>
                        
                        <div class="d-flex ml-3 align-items-center">
                            <p class="chain_prof_exp">+{{state.form_chain_quest.chain_quest_to_display.prof_exp}}</p>
                            <img src="/resource/img/game_ico/prof_exp.svg" alt="image" class="ml-2" />
                        </div>
                        
                   </div>
                </div>
            </div>
             
             
<!--            <div class="final_chain_outer">
                <div class="">
                   <div class="row justify-content-between align-items-center">
                      <div class="col-4">
                         <div class="d-flex align-items-center justify-content-end">
                            <div class="d-flex">
                               <div v-for="item in state.form_chain_quest.chain_quest_to_display.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                                  <div class="d-flex align-items-center justify-content-center">
                                     <p>{{item.amount}}</p>
                                     <img :src="item.item_img" alt="...">
                                  </div>
                               </div>
                            </div>
                            <i class="mdi mdi-arrow-right" style="font-size: 36px;"></i>
                         </div>
                      </div>
                      <div class="col-4">
                         <div class="d-flex justify-content-center">
                            <template v-for="item in state.form_chain_quest.chain_quest_to_display.what_to_get">
                               <template v-for='j in (Number(item.amount))'>
                                  <img :src="item.item_img" :key='item.item_id + j'>
                               </template>
                            </template>
                         </div>
                      </div>
                      <div class="col-4">
                         <div class="d-flex align-items-center justify-content-start">
                            <p class="chain_prof_exp">+{{state.form_chain_quest.chain_quest_to_display.prof_exp}}</p>
                            <img src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                         </div>
                      </div>
                   </div>
                </div>
            </div>-->
             
             
            <div class="chain_modal__save_button">
               <button :disabled="cdata.business_logic.disable_save_button || cdata.system.buttons.common.is_button_blocked"
                  @click="chain_modal_save_action">Apply</button>
            </div>

         </div>
      </div>
   </div>
</div>
</transition>
        
 <!--END CHAIN Modal--> 
                    
                    
                    
                
<!-- AVAINABLE EXTENSION Modal -->
<transition name="bounce" appear>
<div v-if="cdata.business_logic.available_extensions_modal" class="custom-modal_extension" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="d-flex flex-column align-items-center">
            
            <!--SELECTED SLOT-->
            <div class="custom-modal_extension__slot__outer"> 
                <div :class="'custom-modal_extension__slot__header category_'+ cdata.business_logic.available_extensions_category">
                    <p>{{(cdata.business_logic.available_extensions_category).slice(0, -1)}}</p>
                </div>
                
                <div class="custom-modal_extension__slot">
                        <div v-if="cdata.business_logic.chain.selected_extensions[cdata.business_logic.available_extensions_category] != null" class="chain__extension_row__button p-0">
                           <div class="col-3 chain__extension_row__button__tier">
                              <p>Tier <span v-html="cdata.business_logic.chain.selected_extensions[cdata.business_logic.available_extensions_category].tier"></span></p>
                           </div>
                           <div class="col-9 chain_part">
                              <div class="row justify-content-between align-items-center h-100">
                                  
                                 <div class="col-2">
                                    <div class="d-flex align-items-center justify-content-start">
                                       <div class="d-flex">
                                          <div v-for="item in cdata.business_logic.chain.selected_extensions[cdata.business_logic.available_extensions_category].what_to_give" :key="item.item_id" class="d-flex align-items-center">
                                             <div class="d-flex align-items-center justify-content-center">
                                                <p>{{item.amount}}</p>
                                                <img class="ml-1" :src="item.item_img" alt="...">
                                             </div>
                                          </div>
                                       </div>
                                       
                                    </div>
                                 </div>
                                  
                                  <i class="mdi mdi-arrow-right col-2" style="font-size: 36px;"></i>
                                 <div class="col-4">
                                    <div class="row justify-content-center">
                                       <template v-for="item in cdata.business_logic.chain.selected_extensions[cdata.business_logic.available_extensions_category].what_to_get">
                                          <template v-for='j in (Number(item.amount))'>
                                             <img class="col-3 p-0" :src="item.item_img" :key='item.item_id + j' style="max-height: 40px;">
                                          </template>
                                       </template>
                                    </div>
                                 </div>
                                 <div class="col-3">
                                    <div class="d-flex align-items-center justify-content-end">
                                       <p class="chain_prof_exp">+{{cdata.business_logic.chain.selected_extensions[cdata.business_logic.available_extensions_category].prof_exp_reward}}</p>
                                       <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                    
                        <div v-else class="chain__extension_row__button empty">
                           <p>Empty  slot</p>
                        </div>
                </div>
            </div>
            <!--END SELECTED SLOT-->
            
            <!--AVAILABLE SLOTS-->
            <div class="custom-modal_extension__slot__outer mt-3" 
                 v-if="state.form_chain_quest.available_extensions[cdata.business_logic.available_extensions_category].length > 0"> 
                <div :class="'custom-modal_extension__slot__header category_'+ cdata.business_logic.available_extensions_category">
                    <p>Available</p>
                </div>
                
                <div :class="'custom-modal_extension__slot available category_'+ cdata.business_logic.available_extensions_category" 
                     v-for="(available_extension, available_extension_index) in state.form_chain_quest.available_extensions[cdata.business_logic.available_extensions_category]" 
                     :key="available_extension.item_id">
                        <div class="chain__extension_row__button p-0"
                             @click="change_selected_extension(available_extension_index, cdata.business_logic.available_extensions_category)">
                           <div class="col-3 chain__extension_row__button__tier">
                              <p>Tier <span v-html="available_extension.tier"></span></p>
                           </div>
                           <div class="col-9 chain_part">
                              <div class="row justify-content-between align-items-center h-100">
                                 <div class="col-2">
                                    <div class="d-flex align-items-center justify-content-start">
                                       <div class="d-flex">
                                          <div v-for="item in available_extension.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                                             <div class="d-flex align-items-center justify-content-center">
                                                <p>{{item.amount}}</p>
                                                <img class="ml-1" :src="item.item_img" alt="...">
                                             </div>
                                          </div>
                                       </div>
                                       
                                    </div>
                                 </div>
                                  <i class="mdi mdi-arrow-right col-2" style="font-size: 36px;"></i>
                                 <div class="col-4">
                                    <div class="row justify-content-center">
                                       <template v-for="item in available_extension.what_to_get">
                                          <template v-for='j in (Number(item.amount))'>
                                             <img class="col-3 p-0" :src="item.item_img" :key='item.item_id + j' style="max-height: 40px;">
                                          </template>
                                       </template>
                                    </div>
                                 </div>
                                 <div class="col-3">
                                    <div class="d-flex align-items-center justify-content-end">
                                       <p class="chain_prof_exp">+{{available_extension.prof_exp_reward}}</p>
                                       <img class="ml-1" src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                </div>
            </div>
            <!--END AVAILABLE SLOTS-->
            
            <!--DELETE SLOT-->
            <div class="custom-modal_extension__empty_slot mt-5" 
                 v-if="cdata.business_logic.available_extensions_category != 'Formulas'"
                 @click="empty_extension_slot(cdata.business_logic.available_extensions_category)"> 
                <p><i class="mdi mdi-close"></i> Empty the slot</p>
            </div>
            <!--END DELETE SLOT-->
            
            <div v-on:click="cdata.business_logic.available_extensions_modal = false" :class="'chain__extension_row__close_button category_'+ cdata.business_logic.available_extensions_category">
                <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
            </div>
        </div>
        
        
    </div>
  </div>
</div>
</transition>
 <!--END AVAINABLE EXTENSION Modal-->         
                    
                    
                    
<!-- SIDE QUEST Modal -->
<transition name="bounce" appear>
<div v-if="cdata.business_logic.side_quest__logic.show_side_quest__modal" class="custom-modal__side_quest" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      
    <div class="modal-content">
        
      <div class="custom_modal__header">
        <div @click="hide_side_quest__modal" class="d-flex align-items-center" style="cursor: pointer; padding: 10px; background: rgb(234 234 245); box-shadow: 0px 2px 0px #DCE0EA; border-radius: 12px; width: 44px; height: 44px;">
            <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
        </div>
      </div>
        
        
      <div class="custom_modal__body">

          
          <div class="mt-2 mb-4">
              
                <div class="d-flex justify-content-between align-items-center">
                    <p v-if="cdata.business_logic.side_quest__logic.side_quest__data.quest_options.collect_on_complete" class="mr-2" style="font-size: 20px;">You need to give:</p>
                    <p v-else class="mr-2" style="font-size: 20px;">You need to collect:</p>
                    
                    <div class="d-flex align-items-center">
                        <div v-for="item in cdata.business_logic.side_quest__logic.side_quest__data.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                            <div class="custom_tooltip" data-side="top">
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="mr-1" style="font-size: 20px; font-weight: 500;">{{item.amount}}</p>
                                    <img :src="item.item_img" alt="..." style="width:30px; max-height: 50px;">
                                </div>
                                <span class="tooltiptext">{{item.item_name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
              
              
                <div v-if="cdata.business_logic.side_quest__logic.side_quest__data.what_to_get.length > 0" class="d-flex justify-content-between align-items-center mt-2">
                    <p class="mr-2" style="font-size: 20px;">You will get:</p>
                    
                    <div class="d-flex align-items-center">
                        <div v-for="item in cdata.business_logic.side_quest__logic.side_quest__data.what_to_get" :key="item.item_id" class="d-flex align-items-center">
                            <div class="custom_tooltip" data-side="bottom">
                                <div class="d-flex align-items-center justify-content-center">
                                    <p class="mr-1" style="font-size: 20px; font-weight: 500;">{{item.amount}}</p>
                                    <img :src="item.item_img" alt="..." style="width:30px; max-height: 50px;">
                                </div>
                                <span class="tooltiptext">{{item.item_name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
              

              
              
          </div>
          
          <!--Approve BTN-->
          <div>
              <button v-if="cdata.business_logic.side_quest__logic.side_quest__data.ready_to_complete == 1" 
                      @click="approve_side_quest"
                      :disabled="cdata.business_logic.disable_save_button || cdata.system.buttons.common.is_button_blocked"
                      class="mt-4" style="background-color: #55D18E;">Approve</button>
          </div>
          
      </div>
        
    </div>
  </div>
</div> 
</transition>

 <!--END SIDE QUEST Modal-->   
                   
                </div>
                
                
               
            </div>
         </div>
      </div>
   </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_desksweek", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth 
                                        1: "a_test", //r_auth 
                                        2: "json_layouts", //r_auth 
                                        //3: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                    },
                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {
                    disable_save_button: false,
                    show_chain_modal: false,
                    prof_slider_index: 0,
                    
                    chain: {
                        selected_extensions: {
                            Formulas: null,
                            Items: null,
                            Boosters: null
                        }
                    },
                    
                    available_extensions_modal: false,
                    available_extensions_category: "Formulas",
                    
                    side_quest__logic: {
                        show_side_quest__modal: false,
                        side_quest__data: null
                    }
                },
                
                
                
                solved_info__admin_controls: false
            }

    export default {
        name: 'desksweek', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },
        created() { },
        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки

            //IF NEEDED 

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            //this.cdata.system.is_mounted_execute = true;    
            //this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            
            
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___global__level_settings__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__exp_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__gold_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__joker_by_tasks__back(this.$store, this.cdata));
            
            
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
            
            
            this.cdata.solved_info__admin_controls = this.$root._ADMIN().get_solved_info__admin_controls();
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['test'] = this.$store.state['state_tables'].state_test; /* MODIFY */
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */
                    state['layouts'] = this.$store.state['state_tables'].state_layouts; /* MODIFY */
                    state['rg_for_task'] = this.$store.state['state_tables'].state_rg; /* MODIFY */
                    state['desks'] = this.$root._LAYOUT().add_desks_to_state_desks(this.$store, this.cdata, this.$store.state.is_loaded_stages.dynamic_desks_ready);
                    
                    state['game_settings'] = this.$store.state['state_tables'].state_game_settings;/* MODIFY */
                    
                    state['desks_limit'] = this.$root._LAYOUT().add_limit_to_state_desks_limit(this.$store, this.cdata, this.$store.state.is_loaded_stages.level_props__solve_limit);
                    
                    state['desks_exp'] = this.$root._LAYOUT().add_exp_to_state_desks_by_lvl(this.$store, this.cdata, this.$store.state.is_loaded_stages.level_props__exp_by_tasks);
                    
                    state['desks_gold'] = this.$root._LAYOUT().add_gold_to_state_desks_by_lvl(this.$store, this.cdata, this.$store.state.is_loaded_stages.level_props__gold_by_tasks);
                    
                    state['desks_joker'] = this.$root._LAYOUT().add_joker_to_state_desks_by_lvl(this.$store, this.cdata, this.$store.state.is_loaded_stages.level_props__joker_by_tasks);
                    
                    state['player_inventory'] = this.$root._LAYOUT().sort_player_inventory(this.$store, this.cdata, this.$store.state.is_loaded_stages.player_inventory);
                    
                    state['global__profession_list'] = this.$store.state['state_tables'].state_game_settings.global__profession_list;/* MODIFY */
                    state['player_current_profession'] = this.$root._LAYOUT().get_player_current_profession(this.$store, this.cdata, this.$store.state.is_loaded_stages.global__profession_list, this.$store.state.is_loaded_stages.player_professions);
                    
                    state['player_professions'] = this.$root._LAYOUT().get_player_professions(this.$store, this.cdata, state['player_inventory']);
                    
                    state['quest_manager'] = this.$root._LAYOUT().form_quest_manager(this.$store, this.cdata, this.$store.state.is_loaded_stages.quest_manager, this.$store.state.is_loaded_stages.global__inventory_glossary);
                
                    state['form_chain_quest'] = this.$root._LAYOUT().form_chain_quest(this.$store, this.cdata, state['player_professions']);
                    
                    state['lvl_info'] = this.$root._UPDATE().current_player_lvl(this.$store, this.cdata, this.$store.state.is_loaded_stages.global__level_settings);
                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },
            form() {
                let form = {}; // form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                if (
                        this.$store.state.is_loaded_stages.layouts === "fin" &&
                        this.$store.state.is_loaded_stages.auth === "fin" &&
                        this.$store.state.is_loaded_stages.initial_shift === "fin" 
                        )
                { //падает, если неподгружены данные по auth и layouts 
                    let classnum = this.$store.state.state_tables.state_auth.r_auth['classnum'];
                    let lid = this.$store.state.state_tables.state_auth.r_auth['lid']
                    //console.log(classnum); 
                    //console.log(lid); 
                    //console.log(this.$store.state.state_tables.state_layouts.json_layouts); 
                    //console.log(this.$store.state.state_tables.state_layouts.json_layouts.layouts['class_' + classnum][lid]['json_links']); 
                    form['json_links'] = this.$store.state.state_tables.state_layouts.json_layouts.layouts['class_' + classnum][lid]['json_links'];
                } else
                {
                    form['json_links'] = {};
                }



                return form;
            },
            /* #ODIFY */
            /* #OPYPASTE */

        },
        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {



                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                        
                    }


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });
                            console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        }


                    }


                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },
            
            "cdata.business_logic.prof_slider_index": {
                handler: function () {
                    this.cdata.business_logic.chain.selected_extensions.Formulas = null;
                    this.cdata.business_logic.chain.selected_extensions.Items = null;
                    this.cdata.business_logic.chain.selected_extensions.Boosters = null;
                    
                    if(this.state.player_professions && this.state.player_professions[cdata.business_logic.prof_slider_index].is_active == 1){
                        
                        let current_ancestor_data = JSON.parse(this.state.quest_manager.chain_quest[0].ancestor_data);
                        let current_selected_extensions = {
                            Formulas: null,
                            Items: null,
                            Boosters: null
                        };
                        
                        for (const [extension_category, extension_category_obj] of Object.entries(this.state.player_professions[this.cdata.business_logic.prof_slider_index].prof_extensions)){
                            for (const [extensions_unlock_lvl, extensions_data] of Object.entries(extension_category_obj)){
                                if(Number(extensions_data.item_count) > 0){
                                    if(current_ancestor_data.Formulas != null && current_ancestor_data.Formulas == extensions_data.item_id){ current_selected_extensions.Formulas = {...extensions_data} }
                                    if(current_ancestor_data.Items != null && current_ancestor_data.Items == extensions_data.item_id){ current_selected_extensions.Items = {...extensions_data} }
                                    if(current_ancestor_data.Boosters != null && current_ancestor_data.Boosters == extensions_data.item_id){ current_selected_extensions.Boosters = {...extensions_data} }
                                }
                            }
                        }
                        this.cdata.business_logic.chain.selected_extensions = current_selected_extensions;
                    }
                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                //immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            }
        },
        methods: {
            
            approve_side_quest: async function(){
                await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().approve_story_quest__multi_ls_and_back(this.$store, this.cdata, this.cdata.business_logic.side_quest__logic.side_quest__data, this.state['lvl_info'], this.$snotify)).then(
                    this.$snotify.async('Calling the spaceship', 'Loading', () => new Promise((resolve, reject) => {
                        setTimeout(() => resolve({
                          title: 'Success!!!',
                          body: 'Good Job, Traveler!',
                          config: {
                            timeout: 3000,
                            showProgressBar: true,
                            pauseOnHover: true,
                            closeOnClick: true
                          }
                        }), 2000);
                    })),
                    this.$snotify.info("You've unlocked new story!", 'Attention', {
                        timeout: 3000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                    })
                );
        
                this.cdata.business_logic.side_quest__logic.show_side_quest__modal = false;
                this.cdata.business_logic.side_quest__logic.side_quest__data = null;
            },
            
            show_side_quest__modal: function(quest_data){
                this.cdata.business_logic.side_quest__logic.side_quest__data = quest_data;
                this.cdata.business_logic.side_quest__logic.show_side_quest__modal = true;
            },
            
            hide_side_quest__modal: function(){
                this.cdata.business_logic.side_quest__logic.show_side_quest__modal = false;
                this.cdata.business_logic.side_quest__logic.side_quest__data = null;
            },
            
            open_chain_modal: function(){
//                this.cdata.business_logic.prof_slider_index
                this.cdata.business_logic.prof_slider_index = -1;
                this.cdata.business_logic.prof_slider_index = this.state.player_professions.findIndex(prof => prof.is_active == 1);
                
                this.cdata.business_logic.show_chain_modal = true;
            },

            pos0: function (current_deskweek) {
                //json_layout_0002__pos_02 
                let temp1 = current_deskweek.split('__');
                let temp2 = temp1[1].split('_');
                return window._STR().ltrim(temp2[1], '0');
            },
            move_to_card: function (new_route) {

//                cdata.system.buttons.common_without_symmary.is_button_blocked || state.desks_limit[index]['block_by_limit']
                if(!this.cdata.system.buttons.common_without_symmary.is_button_blocked){
                    let current_deskweek = event.target.getAttribute('current_deskweek');
                    if (window._STR().is_not_empty(current_deskweek))
                    {
                        this.$store.dispatch('_business_logic/vuex_set__current_deskweek', {current_deskweek: current_deskweek});
                        this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
                    }
                }

            },
            delete_st_and_rg: async function () {

                console.log("delete_st_and_rg START");
                await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().delete_st_and_rg__multi_ls_and_back(this.$store, this.cdata));
                console.log("delete_st_and_rg FIN");
            },
            
            chain_modal_save_action: async function() {
                
//                console.log(this.state['player_professions'][this.cdata.business_logic.prof_slider_index].name);
                let profession_id = this.state['player_professions'][this.cdata.business_logic.prof_slider_index].prof_id;
                
                await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().update_chain_and_prof__multi_ls_and_back(this.$store, this.cdata, profession_id, this.state.form_chain_quest.chain_quest)).then(
                    this.$snotify.async('Calling the spaceship', 'Loading', () => new Promise((resolve, reject) => {
                        setTimeout(() => resolve({
                          title: 'Success!!!',
                          body: 'We changed energy circuit!',
                          config: {
                            timeout: 3000,
                            showProgressBar: true,
                            pauseOnHover: true,
                            closeOnClick: true
                          }
                        }), 2000);
                    }))
                );
        
                this.cdata.business_logic.show_chain_modal = false;
            },
            
            show_available_extensions: function(extension_category_id) {
                console.log(extension_category_id);
                this.cdata.business_logic.available_extensions_modal = true;
                this.cdata.business_logic.available_extensions_category = extension_category_id;
            },
            
            change_selected_extension: function(extension_index, extension_category_id){
                this.cdata.business_logic.available_extensions_modal = false;
                this.cdata.business_logic.chain.selected_extensions[extension_category_id] = { ...this.state.form_chain_quest.available_extensions[extension_category_id][extension_index]};
            },
            
            empty_extension_slot: function(extension_category_id){
                this.cdata.business_logic.available_extensions_modal = false;
                this.cdata.business_logic.chain.selected_extensions[extension_category_id] = null;
            },
        }


    }
</script>

<style lang="scss" scoped>
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: 0px !important;
        }
    }
    
    
    .noselect{
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }
    
    
    .lvl_body__energy .progress-bar {
        background: linear-gradient(269.77deg, #ADFFD2 33.12%, rgba(1, 194, 255, 0.93) 103.23%);
    }
    
    .grid-margin {
        height: 100%;
    }
    
    .desk_name {
        font-weight: 600;
        font-size: 20px;
        color: #2E3B4B;
    }
    
    .desk_reward {
        font-weight: 500;
        font-size: 15px;
        letter-spacing: -0.2px;
        color: #2E3B4B;
    }
    
    .desk_reward + img {
        width: 20px;
        height: 20px;
    }
    
    .learn_to_full_layout {
        font-weight: 500;
        font-size: 16px;
        color: #2E3B4B;
    }
    
    .chain__outer {
        & img {
            width: 20px;
            height: 20px;
        }
        
        & .chain_prof_exp {
            font-weight: 500;
            font-size: 17px;
            color: #051032;
        }
        
        & .progress-bar {
            background: linear-gradient(90deg, #F85633 0%, #F8B533 100%);
            border-radius: 30px;
        }
        
        & .chain_progress p{
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #000000;
        }
        
        & button {
            width: 100%;
            padding: 8px 24px;
            background: #F4F5FD;
            box-shadow: 0px 4px 0px #D9DBE7;
            border: none;
            border-radius: 14px;
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
        }
    }
    
    
    .side_quests__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & img {
            width: 24px;
        }
        & p,
        & i {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
        }
    }
    
@media (max-width: 1199px){
    .grid-margin {
        height: initial;
    }
}







.lvl_header .desk_name {
    white-space: nowrap;
    
    & + img {
        max-width: 60px;
        max-height: 20px;
    }
}





/*MODAL*/
.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    background: #F3F4FB;
    
    & .modal_custom_dialog {
        width: 100%;
        height: 100%;
    }

    
    & .modal-content {
        border: none;
        height: 100%;
        background-color: #F3F4FB;
    }
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}




/*CAROUSEL*/
/*#chain_modal__prof_slider {
    background: transparent !important;
    
    & .carousel-item {
            background: transparent !important;
    }*/
/*  margin-left: 50px;
  margin-right: 50px;
  
    & .carousel-control-prev {
        margin-left: -25px;
    }

    & .carousel-control-next {
      margin-right: -25px;
    }*/
/*}*/
/*END CAROUSEL*/


    .final_chain_outer {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 16px 0px;
        width: 100%;
        
        & img {
            width: 30px;
        }
        
        & p {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
        }
    }

    .prof_card {
        position:relative; 
        width: 270px;
        height: 340px; 
        background-color: white;
        box-shadow: 0px 4px 0px #E5E6EF; 
        border-radius: 12px;
        
        
        &.active {
            border: 2px solid #F6D45A;
        }
        
        & .prof_card_resources {
            width: 40px; 
            height:40px;
        }
    }
    
    .prof_card__header {
        & img{
            width: 40px; 
            height: 40px;
        }

        & p {
            font-size: 16px; 
            color: #2E3B4B;
        }

        & .prof_card__header__prof_name {
            font-weight: 600;
        }

        & .prof_card__header__prof_lvl_outer {
            padding: 4px 15px;
            background: #F4F5FD; 
            border-radius: 40px;
        }

        & .prof_card__header__prof_lvl {
            font-weight: 500;
        }
    }
    
    .active_prof_signboard__outer {
        position: absolute;
        bottom: -10%;
        left: 50%;
        transform: translate(-50%, 10%);
    }
    
    .active_prof_signboard {
        background: #F7D45A;
        border-radius: 0px 0px 8px 8px;
        padding: 2px 15px;
        height: 35px;
        
        & p {
            color: white;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .chain_modal__save_button {
        text-align: center;
        
        & button {
            width: 350px;
            height: 40px;
            background: #55D18E;
            box-shadow: 0px 4px 0px #4ABB7E;
            border-radius: 12px;
            padding: 8px 24px;
            border: none;

            color: #FFFFFF;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;

            &:disabled {
                opacity: 0.5;
            }
        }
    }
    
    .custom_modal__body {
         display: flex;
         flex-direction: column;
         justify-content: space-around;
    }
    
    @media(max-width: 1200px), (max-height: 700px) {
        .custom_modal__body {
            display: flex;
            flex-direction: column;
            justify-content: inherit;
        }
        
        .chain_modal__save_button {
            margin-top: 1rem;
        }
    }

    .custom_modal__body .container-fluid {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .slider_prof_picture {
        border-radius: 12px;
    }
    
    
    
/*EXTENSION PART*/
    .chain__extension_row {
        justify-content: space-between;
        
        & img {
            width: 20px;
            height: 20px;
        }
        
        & .chain__extension_row__name {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            
            & p{
                color: #2E3B4B;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
            }
        }
        
        & .chain__extension_row__button__outer {
            cursor: pointer;
            background: #FFFFFF;
            box-shadow: 0px 4px 0px #E5E6EF;
            border-radius: 12px;
            
            /*width: 400px;*/
            height: 65px;
            
            & .chain__extension_row__button__chevron {
                height: 100%;
                border-radius: 0px 12px 12px 0px;
                
                display: flex;
                justify-content: center;
                align-items: center;
                
                & i {
                    color: white;
                    font-size: 30px;
                }
            }
            
            & .chain__extension_row__button__chevron.category_formula {
                background: #49BDF4;
            }
            
            & .chain__extension_row__button__chevron.category_item {
                background: #8C90FF;
            }
            
            & .chain__extension_row__button__chevron.category_booster {
                background: #FF9E4A;
            }
            
            & .chain__extension_row__button {
                display: flex;
                
                & .chain_part p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                }
                
                & .chain__extension_row__button__tier {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    border-right: 1px solid #DCE0EA;
                    
                    & p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: #2E3B4B;
                    }
                }
                
                & .chain__extension_row__button__booster_limit {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-left: 1px solid #DCE0EA;
                    
                    & p {
                        white-space: nowrap;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
                
            }
            
            & .chain__extension_row__button.empty{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #DCE0EA;
            }
        }
        
        & .chain_part {
            & p {
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                color: #2E3B4B;
            }

            & img {
                width: 20px;
            }
        }
    }
/*END EXTENSION PART*/
    

.bonus_exp_for_slots {
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(193, 195, 210, 0.2);
    border-radius: 12px;
    
    & p {
        color: #2E3B4B;
        /*font-family: 'M PLUS 1';*/
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
    }
    
    & img {
        width: 20px;
    }
}


    @media (max-width: 1450px){
        #chain_modal__prof_slider {
            height: 350px;
            
            & .prof_card {
                width: 220px;
                height: 285px;

                & .prof_card__header {
                    & img {
                        width: 30px;
                        height: 30px;
                    }

                    & p {
                        font-size: 14px;
                    }

                    & .prof_card__header__prof_lvl_outer {
                        padding: 4px 12px;
                    }
                }

                & .prof_card_resources {
                    width: 30px;
                    height: 30px;
                }

                & .active_prof_signboard__outer {
                    & .active_prof_signboard {
                        padding: 2px 10px;
                        height: 30px;
                    }

                    & p {
                        font-size: 16px;
                    }
                }
            }
        }
        

    }
/*END MODAL*/





/*AVAINABLE EXTENSION MODAL*/
.custom-modal_extension {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    background: rgba(67, 67, 67, 0.5);
    mix-blend-mode: normal;
    backdrop-filter: blur(2px);
    
    & img {
        width: 24px;
    }
    
    & .modal-content {
        border: none;
        border-radius: 16px;
        /*padding: 24px;*/
        position: relative;
        
        background-color: transparent;
    }
    
    & .custom-modal_extension__slot__outer {
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    
    & .custom-modal_extension__slot {
/*        display: flex;
        justify-content: center;
        align-items: center;
        */
        cursor: pointer;
        background: #FFFFFF;
        /*box-shadow: 0px 4px 0px #E5E6EF;*/
        border-radius: 0px 0px 12px 12px;

        width: 400px;
        height: 65px;
        
        &.available {
            border-radius: 0px;
            transition: .3s;
            
            &:hover {
                transition: .3s;
                
                &.category_Formulas {
                    background: #DDF4FF;
                }
                &.category_Items {
                    background: #E6E7FF;
                }
                &.category_Boosters {
                    background: #FFEEDE;
                }
            }
            
            &:last-child {
                border-radius: 0px 0px 12px 12px;
                filter: drop-shadow(0px 4px 0px #D3D8E7);
            }
        }
    }
            
            & .chain__extension_row__button {
                display: flex;
                height: 100%;
                
                & .chain_part p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                }
                
                & .chain__extension_row__button__tier {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    
                    border-right: 1px solid #DCE0EA;
                    
                    & p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: #2E3B4B;
                    }
                }
                
            }
            
            & .chain__extension_row__button.empty{
                
                display: flex;
                justify-content: center;
                align-items: center;
                
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #DCE0EA;
            }
            
    & .custom-modal_extension__slot__header {
        width: 400px;
        height: 32px;
        border-radius: 12px 12px 0px 0px;
        padding: 0px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        & p {
            color: #FFFFFF;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
        }
        
        &.category_Formulas {
            background: #49BDF4;
        }
        &.category_Items {
            background: #8C90FF;
        }
        &.category_Boosters {
            background: #FF9E4A;
        }
    }
    
    
    & .chain__extension_row__close_button {
        position: absolute;
        top: 0;
        right: 0;
        
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        
        border-radius: 12px;
        width: 44px;
        height: 44px;
        
        & i {
            color: white;
        }
        
        &.category_Formulas {
            background: #49BDF4;
            box-shadow: 0px 2px 0px #40AADD;
        }
        &.category_Items {
            background: #8C90FF;
            box-shadow: 0px 2px 0px #5C60D5;
        }
        &.category_Boosters {
            background: #FF9E4A;
            box-shadow: 0px 2px 0px #E78E42;
        }
    }
    
    & .custom-modal_extension__empty_slot {
        width: 400px;
        background: #FF7697;
        box-shadow: 0px 4px 0px #DC6582;
        border-radius: 12px;
        padding: 8px 24px;
        cursor: pointer;
        
        & p {
            text-align: center;
            color: white;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
        }
    }
}
/*END AVAINABLE EXTENSION MODAL*/




.desks_outer {
        margin-bottom: 2rem;
        
        &:nth-of-type(5),
        &:nth-of-type(6) {
            margin-bottom: 0;
        }
}


.bonus_income {
    background: rgba(255, 158, 74, 0.3); 
    padding: 5px 7px; 
    border: 1px solid #FF9E4A; 
    border-radius: 8px;
    transition: .5s;
    
    & p {
        font-weight: 600;
        transition: .5s;
    }
    
    & img {
        width: 20px;
        transition: .5s;
    }
}

.learn_to_full_layout__outer {
    display: flex;
    align-items:center;
    
    & img {
        width: 32px;
        margin-right: 0.25rem;
        transition: .5s;
    }
    
    & .learn_to_full_layout {
        padding: 0;
        margin: 0;
        margin-right: 0.25rem;
        transition: .5s;
    }
    
    & i {
        font-size:24px; 
        color: #55D18E;
        transition: .5s;
    }
}

@media (max-width: 1200px){
    .desks_outer:nth-of-type(5),
    .desks_outer:nth-of-type(6) {
        margin-bottom: 2rem;
    }
}







.bounce-enter-active {
  animation: bounce-in .8s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}




.desk_reward__outer {
    margin-right: 0.5rem;
    transition: 0.5s;
    
    &:last-child {
        margin-right: 0;
    }
    
    & .desk_reward_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #F1F4F9, #F1F4F9), #F1F4F9;
        border-radius: 16px;
        padding: 2px 10px;
        
        transition: .5s;
    }
}


/*SIDE QUEST MODAL*/
.custom-modal__side_quest {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    background: #d3d3d38c;
    
    & .modal-content {
        border: none;
        border-radius: 16px;
        padding: 24px;
    }
    
    & .custom_modal__header {
        display:flex;
        align-items: flex-start;
        justify-content: flex-end;
    }
    
    & .chain_part {
        & p {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            color: #2E3B4B;
        }
        
        & img {
            width: 28px;
        }
    }
    
    & .custom_modal__body button {
        border: none;
        padding: 10px 24px;
        width: 100%;
        border-radius: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
    }
}
/*SIDE QUEST END MODAL*/

.side_quest__button {
    width: 100%;
    padding: 8px 24px;
    background: #F4F5FD;
    box-shadow: 0px 4px 0px #D9DBE7;
    border: none;
    border-radius: 14px;
    color: #2E3B4B;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    
    &.active {
        background-color: #55D18E;
        box-shadow: 0px 4px 0px #4abb7e;
        color: #FFF;
    }
}
</style>

<style lang="scss">
    #chain_modal__prof_slider {
        display: flex;
        justify-content: center;
        width: 500px;
        height: 400px;
        
        & .carousel-control-prev, .carousel-control-next {
            top: -30%;
            color: #fff;
            text-decoration: none;
            outline: 0;
            opacity: 1;
        }
        
        & .carousel-control-prev-icon {
          /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;*/
            background-image: url("/resource/img/slider_prev.svg");
            width: 70px;
            height: 70px;
        }

        & .carousel-control-next-icon {
          /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;*/
            background-image: url("/resource/img/slider_next.svg");
            width: 70px;
            height: 70px;
        }

        & .carousel-inner {
            width: 270px;
        } 
    }
    
    @media (max-width: 720px) {
        #chain_modal__prof_slider {
            & .carousel-control-prev, .carousel-control-next {
                top: 0;
            }
        }
    }
    
    
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}


@media (max-width: 1450px){
        #chain_modal__prof_slider {
            & .carousel-control-next-icon,
            & .carousel-control-prev-icon{
                width: 50px;
                height: 50px;
            }
        }
}

</style>